<template>
  <div>
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="true"
    />
    <ImportBourseAuxLivresComponent
      @import="({ fichier, done }) => {
        uploadImportBourseAuxLivres({fichier: fichier}).then(() => {
          startInterval();
          done(); 
        });
      }"
    />
    <RapportImportBourseAuxLivresComponent
      :dernier-import="dernierImport"
      @telecharger="() => { 
        downloadingFile = {
          url: '/importBLFileLog',
        }
      }"
    />
    <RedirectionBourseAuxLivresComponent />
    <Downloader
      :downloading-file="downloadingFile"
      label="rapport import Bourse aux Livres"
      @downloadSuccess="() => downloadingFile = null"
    />
  </div>
</template>

<script>
import ImportBourseAuxLivresComponent from '../../components/importsExports/ImportBourseAuxLivresComponent';
import RapportImportBourseAuxLivresComponent from '../../components/importsExports/RapportImportBourseAuxLivresComponent';
import RedirectionBourseAuxLivresComponent from '../../components/importsExports/RedirectionBourseAuxLivresComponent';
import Downloader from '../../components/partials/Downloader';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ImportBourseAuxLivresComponent,
    RapportImportBourseAuxLivresComponent,
    RedirectionBourseAuxLivresComponent,
    Downloader,
  },
  data() {
    return {
      downloadingFile: null,
      intervalId: null,
    };
  },
  computed: {
    ...mapGetters('importsExports/importBourseAuxLivres', {
      isLoading: 'isLoading',
      dernierImport: 'getDernierImport',
    }),
  },
  created() {
    this.loadDateDernierImport();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    ...mapActions('importsExports/importBourseAuxLivres', [
      'uploadImportBourseAuxLivres',
      'loadDateDernierImport',
    ]),
    startInterval() {
      if (this.intervalId === null) {
        this.intervalId = setInterval(() => {
          this.loadDateDernierImport().then(() => {
            if (this.dernierImport.etat.startsWith('TERMINE')) {
              this.stopInterval();
            }
          });
        }, 1000);
      }
    },
    stopInterval() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
  },
};
</script>
