<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Import bourse aux livres
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-tooltip bottom>
            <!-- Il semble que le mime type application/sql ne soit pas encore supporté -->
            <template v-slot:activator="{ on }">
              <v-file-input
                ref="inputForm"
                v-model="fichier"
                v-validate="'required' /* |mimes:application/sql */"
                show-size
                data-vv-name="fichier"
                :error-messages="errors.collect('fichier')"
                accept="application/sql,.sql"
                label="Fichier à importer *"
                :disabled="forceDisabled"
                v-on="on"
              />
            </template>
            <ul>
              <li>Format du fichier : sql</li>
            </ul>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="primary"
            :disabled="forceDisabled || !fichier"
            @click="forceDisabled = true; $emit('import', { fichier: fichier, done: () => reset() });"
          >
            Lancer l'import
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ImportBourseAuxLivresComponent',
  data() {
    return {
      fichier: null,
      forceDisabled: false,
    };
  },
  methods: {
      reset() {
          this.$refs.inputForm.reset();
          this.$validator.reset();
          this.forceDisabled = false;
      },
  },
};
</script>
