<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Rapport d'import bourse aux livres
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card-text>
            <p v-if="dernierImport != null">
              Dernier import du {{ dernierImport.date | formatDate }} : <span :class="ETAT_IMPORT_COLORS[dernierImport.etat] + '--text'"> {{ ETAT_IMPORT_TEXT[dernierImport.etat] }} </span>
            </p>
            <p v-else>
              Aucun rapport n'est disponible.
            </p>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="primary"
            :disabled="!dernierImport"
            @click="$emit('telecharger')"
          >
            Télécharger le dernier rapport
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'RapportImportBourseAuxLivresComponent',
  props: {
    dernierImport: { type: Object, default: null },
  },
  data() {
    return {
      ETAT_IMPORT_COLORS: {
        'DEMARRE': 'orange',
        'NORMA_BL_IMPORTE': 'amber',
        'FCPE_GESTION_ADHERENTS_MIGRE': 'blue',
        'TERMINE_OK': 'green',
        'TERMINE_ERREUR': 'red',
      },
      ETAT_IMPORT_TEXT: {
        'DEMARRE': 'Chargement du fichier en cours...',
        'NORMA_BL_IMPORTE': 'Migration en cours...',
        'FCPE_GESTION_ADHERENTS_MIGRE': 'Fin en cours...',
        'TERMINE_OK': 'Terminé',
        'TERMINE_ERREUR': 'Terminé avec des erreurs',
      },
    };
  },
};
</script>