<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Validation
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="primary"
            @click="$router.push('validation-import-bourse-livres')"
          >
            Accéder aux écrans de validation
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'RedirectionBourseAuxLivresComponent',
  data() {
    return { };
  },
};
</script>
